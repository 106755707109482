

@media screen and (max-width: 800px) {
  .flex-map {
    flex-direction: column;
  }
}

@media screen and (min-width: 800px) {
  .flex-map {
    flex-direction: row;
  }
}

.coming-soon {
  background-color: #ff8cee;
  box-shadow: 0.1em 1em 1em -0em #ccc;
  padding: 1em 1em 2em 1em;
  margin-right: 1em;
  margin-left: 1em;
  color: #fff;
}

.map-card {
  margin-top: 1em;
  box-shadow: 0.1em 1em 1em -0em #ccc;
  padding: 0 1em 2em 1em;
  margin-right: 1em;
  margin-left: 1em;
}

.location-footer {
  margin-top: 2em;
}

.map-card a {
  text-decoration: none !important;
}

.map-card a:hover {
  text-decoration: underline !important;
  text-decoration-color: #ccc;
	-webkit-text-decoration-color: #ccc;
}