.navcontainer {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.navbar {
  width: 100%;
  min-width: 360px;
  letter-spacing: 0;
  background-color: #fff;
  padding: 8px 16px;
  box-shadow: 0px 1.2em 2em -1em #ccc;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: fixed;
  z-index: 10;
}

.navbar a {
  text-align: left;
  display: inline-block;
  padding: 8px 16px;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
}

.navbar b {
  font-size: 1.25em;
}

.navbtn {
  padding: 0.05em 0.5em 0 0 !important;
  font-family: 'Fredoka', sans-serif !important;
}

.logobtn {
  padding: 0 0.9em 0 1em !important;
  left: 0;
  position: absolute;
}

.navlogo {
  width: 32px;
  height: 32px;
  margin-right: 0.5em;
}

.linkdiv {
  margin-top: 0.25em;
}

.links {
  margin-left: auto;
}

@media (max-width: 450px) {
  .overlay {
    margin: 5em 1em 5em 1em;
  }
  .links {
    display: none;
  }
}

@media (max-width: 900px) and (min-width: 450px) {
  .overlay {
    margin: 5em 1em 5em 1em;
  }
  .links {
    display: none;
  }
}

/* @media (max-width: 850px) and (min-width: 750px) {
  .overlay {
    margin: 4em 2em 2em 3em !important;
  }
  .navbtn {
    padding: 0 0.28em 0 !important;
    font-size: 0.81em;
  }
  .burger-menu {
    display: none;
  }
} */

@media (max-width: 1150px) and (min-width: 900px) {
  .overlay {
    margin: 4em 5em 5em 5em !important;
  }
  /* .navbtn {
    padding: 0 0.3em 0 !important;
    font-size: 0.85em;
  } */
  .burger-menu {
    display: none;
  }
}

/* @media (min-width: 950px) and (max-width: 1150px) {
  .navbtn {
    padding: 0 0.5em 0 !important;
  }
  .burger-menu {
    display: none;
  }
} */

@media (min-width: 1150px) {
  .burger-menu {
    display: none;
  }
}