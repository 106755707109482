.about-container {
  padding: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.photo {
  margin: auto;
}
