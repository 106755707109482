.menu {
  position: absolute;
  top: 3em;
  right: 0;
  width: 10em;
  height: fit-content;
  background-color: #fff;
  z-index: 2;
  color: #333;
  text-align: left;
  padding-bottom: 1em;
  right: -10em;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.menu img {
  max-width: 150px;
  margin: 1em 0 0 2em;
}

.menu .link-container {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.menu a {
  text-decoration: none;
  margin: 0.5em 0 0 1em;
}

.menu a:hover {
  text-decoration: underline;
}
