#burger-icon span{
  display: block;
  margin: 5px 5px 5px 10px;
  height: 5px;
  width: 30px;
  background: #555;
  border-radius: 9px;
  cursor:pointer; 
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  position:relative;
  left:0px;
}

#burger-box:checked ~ #burger-icon span:nth-child(1){
  -webkit-transform: rotate(135deg) translate(7px,7px);
  -moz-transform: rotate(135deg) translate(7px,7px);
  -o-transform: rotate(135deg) translate(7px,7px);
  transform: rotate(135deg) translate(7px,-7px);
}

#burger-box:checked ~ #burger-icon span:nth-child(2){
   opacity: 0;
}

#burger-box:checked ~ #burger-icon span:nth-child(3){
  -webkit-transform: rotate(-135deg) translate(7px, 7px);
  -moz-transform: rotate(-135deg) translate(7px, 7px);
  -o-transform: rotate(-135deg) translate(7px, 7px);
  transform: rotate(-135deg) translate(7px, 7px);
}

#burger-box:checked ~ #bmenu {
  transform: translateX(-10em);
  transition-duration: 0.4s;
}

#burger-box:not(:checked) ~ #bmenu {
  transform: translateX(10em);
  transition-duration: 0.4s;
}

#burger-box[type="checkbox"]{
  display:none;
}
