body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Fredoka', sans-serif !important;
  background-color: #94ACFF1A;
  min-width: 360px;
}

.container {
  max-width: 1500px;
}

.bookbtn {
  width: 8em !important;
  float: left;
  padding: 0.5em !important;
  margin: 0 !important;
  margin-left: auto !important;
  font-size: small;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

@media screen and (max-width: 900px) {
  body {
    font-size: 12pt !important;
  }
}

@media screen and (min-width: 900px) {
  .bookbtn {
    display:none;
  }
}

.top-label {
  font-family: 'Fredoka';
  font-size: 2.5em;
  top: -1em;
  position: relative;
  letter-spacing: 0.01em;
}

.pageHeight {
  height: 800px;
}

.upper {
  text-transform: uppercase
}

.flex {
  display: flex;
}

.flex.flex-col {
  flex-direction: column;
}

.flex.flex-col-rev {
  flex-direction: column;
}

.flex.flex-wrap {
  flex-wrap: wrap;
}

.card-bg {
  margin-top: 1.5em;
  box-shadow: 0px 1.2em 2em -1em #ccc;
  padding: 0 2em 3em 2em;
  background-color: #fff;
}

.small {
  font-size: 0.8em;
}

.topxl {
  margin-top: 15em !important;
}

.block {
  position: absolute;
  height: 15em;
  width: 100%;
  top: 25%;
}

.expander {
  display: flex;
  flex-grow: 1;
}

@media screen and (max-width: 900px) {
  .container {
    padding: 8px;
    margin-left: 0;
    margin-right: 0;    
  }

  .flex.flex-row {
    flex-direction: column;
  }
  
  .flex.flex-row-rev {
    flex-direction: column-reverse;
  }
}

@media (max-width: 1100px) {
  .block {
    display: none;
  }
}

@media screen and (min-width: 900px) and (max-width: 1599px) {
  .container {
    padding: 16;
    margin-left: 1em;
    margin-right: 1em;    
  }

  .flex.flex-row {
    flex-direction: row;
  }
}

@media screen and (min-width: 1600px) {
  .container {
    padding: 32px;
    margin-left: auto;
    margin-right: auto;    
  }

  .flex.flex-row {
    flex-direction: row;
  }
}

footer {
  margin-top: 2em;
}