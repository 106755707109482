ul {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 2em 0;
}

li {
  padding-left: 25px;
  letter-spacing: -0.04em;
  font-size: 1em;
}

li:before {    
  font-family: 'FontAwesome';
  content: '\f00c';
  margin:0 5px 0 -15px;
  font-size: 0.8em;
}

.btn {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  background-color: #BD4AB5CC;
  border: none;
  color: #FFFFFF;
  padding: 15px 32px;
  text-align: center;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 100%
}

.auto-margins {
  margin: auto;
}