.modal {  
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay {
  position: fixed;
  background-color: #fff;  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 2em 2em 1em #ddd;
  padding: 0.1em;
}