:root {
  --pink: hsl(304.17, 46.56%, 51.57%);
  --mauve: hsl(226.83, 41%, 60.78%);
  --blue: hsl(212, 86%, 64%);
  --red: hsl(0, 78%, 62%);
  --cyan: hsl(180, 62%, 55%);
  --orange: hsl(34, 97%, 64%);
  --varyDarkBlue: hsl(234, 12%, 34%);
  --grayishBlue: hsl(229, 6%, 66%);
  --veryLightGray: hsl(0, 0%, 98%);
  --weight1: 200;
  --weight2: 400;
  --weight3: 600;
}

/* body {
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  background-color: var(--veryLightGray);
} */

.attribution { 
  font-size: 11px; text-align: center; 
}
.attribution a { 
  color: hsl(228, 45%, 44%); 
}

.padded {
  padding: 0.3em;
}

@media (max-width: 400px) {
  h1 {
      font-size: 1.5rem;
  }
}

.header {
  text-align: center;
  line-height: 0.8;
  margin-bottom: 50px;
  margin-top: 100px;
}

.header p {
  margin: 0 auto;
  line-height: 2;
  color: var(--grayishBlue);
}


.box p {
  color: var(--grayishBlue);
}

.box {
  border-radius: 0.5em;
  box-shadow: 0px 1.2em 2em -1em var(--grayishBlue);
  padding: 0.5em;
  margin: 1em;  
  font-size: 1.3em !important;
}

.box img {
  float: right;
}

@media (max-width: 950px) and (min-width: 450px) {
  .box {
      text-align: center;
  }
}

.cyan {
  border-top: 3px solid var(--cyan);
}
.red {
  border-top: 3px solid var(--red);
}
.blue {
  border-top: 3px solid var(--blue);
}
.orange {
  border-top: 3px solid var(--orange);
}
.pink {
  border-top: 3px solid #BD4AB5CC;
}

h2 {
  color: var(--varyDarkBlue);
  font-weight: var(--weight3);
  font-size: 1.2em;
}


@media (min-width: 800px) {
  .row-container {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .box-down {
      position: relative;
      top: 150px;
  }
  .box {
      min-width: 30%;
   
  }
  .header p {
      width: 30%;
  }
  
}