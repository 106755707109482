.theme-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 8px;
}

.theme-box {
  margin: 1em;
  min-width: 26%;
}

.baseimg {
  max-height: 210px;
  overflow: hidden;
  border: 1px solid #ccc;
  margin-top: 1em;
}