.frame {
  display:block;
  border:none;
  width:100%;
  height:100%;
}

@media (max-width: 500) {
  .overlay {
    margin: 5em 1em 5em 1em;
  }
}

@media (max-width: 950px) and (min-width: 500px) {
  .overlay {
    margin: 4em 2em 5em 2em;;
  }
}

@media (min-width: 950px) {
  .overlay {
    margin: 5em 5em 5em 5em;
  }
}