.welcome-container {
  padding: 1em;
}

.pink-bold {
  color: var(--pink);
  font-weight: 400;
}

.mauve-bold {
  color: var(--mauve);
  font-weight: 400;
}
